.app {
  padding: 10% 15% 15% 15%;
  font-family: sans-serif;
}

.app-dark-mode {
  background-color: #1b1b1b;
  color: whitesmoke;
}

@media (max-width: 800px) {
  .app {
    padding: 10% 5% 10% 5%;
  }
}

.profile-title {
  margin-bottom: 2%;
}

.full-name {
  font-size: 50px;
  font-weight: 400;
}

.profile-subtitle {
  font-weight: 500;
  margin-top: 8px;
  margin-left: 2px;
}

p {
  font-weight: 350;
  padding-bottom: 10px;
}

a {
  color: rgb(71, 160, 215);
  font-weight: 350;
}

.section {
  margin-top: 30px;
}

.section-title {
  font-size: 35px;
  font-weight: 300;
}

.subsection-title {
  font-size: 25px;
  font-weight: 300;
  margin: 30px 0 25px 0;
}

.contact-info {
  font-size: 20px;
  margin-top: 20px;
  line-height: 30px;
}

ul {
  font-weight: 350;
  padding-bottom: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
