@media (min-width: 800px) {
    .biopic {
        display: flex;
    }
    
    .biopic-pic-container {
        margin: 25px 20px 0 50px;
    }

    .biopic-pic {
        height: 300px;
    }
}

@media (max-width: 800px) {
    .biopic-pic-container {
        display: flex;
        justify-content: center;
    }

    .biopic-pic {
        height: 300px;
        margin: 30px 0 30px 0;
    }
}