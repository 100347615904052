.resume-item {
    display: flex;
    margin-bottom: 40px;
}

.resume-item-copy {
    padding: 6px 0 0 20px;
}

.resume-item-company {
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 5px;
}

.resume-item-title {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 10px;
}

.resume-item-description {
    font-size: 16px;
}

.uc-san-diego {
    height: 120px;
    margin: -10px;
}

.tinder {
    height: 95px;
    margin-left: 3px;
}

.spotify {
    height: 100px;
}

.lobe {
    height: 186px;
    margin: -43px -41px -35px -45px;
}

.microsoft {
    height: 100px;
    margin: 0 0 0 0;
}

.amazon {
    height: 94px;
    margin: 5px 0 0 4px;
}

.marvell {
    height: 95px;
    margin: 5px 0 0 3px;
}